<template lang="pug">
  .tag.d-flex(
    @click="$emit('click')"
  )
    .tag__text {{ tag.title }}
    b-button.tag__remove(
      variant="outline-danger"
      :disabled="disabled"
      @click="$emit('remove', tag.id)"
    )
      b-icon(icon="trash")
</template>

<script>
  export default {
    name: "TagLabel",
    props: {
      tag: Object,
      disabled: Boolean
    }
  };
</script>

<style scoped lang="scss">
  .tag {
    position: relative;
    outline: none !important;
    color: #7e858e;
    background: rgba(126, 133, 142, 0.04);
    border: 1px solid rgba(126, 133, 142, 0.04);
    border-radius: 4px;
    padding: 4px 40px 4px 16px;
    margin-right: 8px;
    margin-bottom: 8px;

    &.active {
      color: #FFFFFF;
      background: #7e858e;
      border-color: #7e858e;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }

    &__remove {
      position: absolute;
      top: 0;
      right: 0;
      padding: 3px 5px;
    }
  }
</style>
