<template lang="pug">
  .d-block
    b-overlay(
      :show="$api.loading"
      spinner-small
      spinner-variant="success"
    )
      .d-flex
        .d-block.flex-grow-1
          b-form-input(
            v-model="selectedGame"
            placeholder="Enter game title or game ID"
            list="gameSelectList"
          )
          datalist(id="gameSelectList")
            option(v-for="game in gameSelectList") {{ game }}
        b-button.ms-3(variant="success" @click="selectAllGames") Show all striked comments
</template>

<script>
  export default {
    name: "GameSelect",
    data() {
      return {
        selectedGame: null,
        gameList: null,
        gameSelectList: null
      };
    },
    watch: {
      selectedGame() {
        if (!this.selectedGame) return;
        const currentGame = this.gameList.find(
          (game) =>
            game.title === this.selectedGame ||
            this.selectedGame.includes(game.id.toString())
        );
        if (currentGame) {
          this.$emit("selected", currentGame);
        }
      }
    },
    mounted() {
      this.getGames();
    },
    methods: {
      selectAllGames() {
        this.selectedGame = null;
        this.$emit("selectAll");
      },
      getGames() {
        this.$api
          .authorize(this.$firebase.getToken())
          .get("/games/", {
            params: {
              status: "ALL",
              limit: 0
            }
          })
          .then((data) => {
            this.gameList = data;
            this.gameSelectList = data.map(
              (game) => `${game.title} (${game.id})`
            );
          });
      }
    }
  };
</script>
