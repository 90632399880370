<template lang="pug">
  .game-data
    .game-body.flex-grow-1.p-3
      b-overlay(
        :show="$api.loading"
        spinner-variant="success"
      )
        .d-block(v-if="game")
          h3.mb-4 {{ game.title }}
          .d-flex.mb-2.pb-2.border-bottom
            .data-label
              strong.fs-6.lh-1 ID
            .d-block.flex-grow-1
              b-form-input(
                v-model="game.id"
                :disabled="!isNewGame"
                :state="inputIdError"
                trim
                aria-describedby="gameId"
                @input="validateGameId"
              )
              b-form-invalid-feedback(id="gameId") This ID is already exist
          .d-flex.mb-2.pb-2.border-bottom
            .data-label
              strong.fs-6.lh-1 Title
            .d-block.flex-grow-1
              b-form-input(
                v-model="game.title"
                :disabled="!isEditMode"
                @blur="fillSlug"
              )
          .d-flex.mb-2.pb-2.border-bottom
            .data-label
              strong.fs-6.lh-1 Slug
            .d-block.flex-grow-1
              b-form-input(
                v-model="game.slug"
                :disabled="!isEditMode"
              )
          .d-flex.mb-2.pb-2.border-bottom
            .data-label
              strong.fs-6.lh-1 Description
            .d-block.flex-grow-1
              b-form-textarea(
                v-model="game.description"
                :disabled="!isEditMode"
                rows="5"
                max-rows="15"
              )
          .d-flex.mb-2.pb-2.border-bottom
            .data-label
              strong.fs-6.lh-1 SRC
            .d-block.flex-grow-1
              b-form-input(
                v-model="game.src"
                :disabled="!isEditMode"
              )
          .d-flex.mb-2.pb-2.border-bottom(v-if="game")
            .data-label
              strong.fs-6.lh-1 Game content
            .d-flex.flex-grow-1
              .d-block.w-75.pe-2
                b-alert.m-0(
                  show
                  variant="danger"
                )
                  p.m-0 Warning! The content of the game is saved immediately, regardless of other parameters.
                    =" "
                    | Canceling editing will not return the game content to its original state!
              .d-block.w-25
                b-button.w-100(
                  variant="outline-success"
                  @click="editContentModalOpened = true"
                ) Edit
          .d-flex.mb-2.pb-2.border-bottom
            .data-label
              strong.fs-6.lh-1 Category
            .d-block.flex-grow-1
              b-form-select(
                v-model="game.category"
                :disabled="!isEditMode"
                :options="categoriesList"
              )
          .d-flex.mb-2.pb-2.border-bottom
            .data-label
              strong.fs-6.lh-1 Aspect Ratio
            .d-block.flex-grow-1
              b-form-input(
                v-model="game.aspect_ratio"
                :disabled="!isEditMode"
                type="number"
              )
          .d-flex.mb-2.pb-2.border-bottom
            .data-label
              strong.fs-6.lh-1 Status
            .d-block.flex-grow-1
              b-form-select(
                v-model="game.status"
                :disabled="!isEditMode"
                :options="gameStatuses"
              )
          .d-flex.mb-2.pb-2.border-bottom(v-if="game")
            .data-label
              strong.fs-6.lh-1 Tags
            .d-block.flex-grow-1
              GameDetailTags(
                :tags="game.tags"
                :disabled="!isEditMode"
                @change="changeTagList"
              )
          .d-flex.mb-2.pb-2.border-bottom(v-if="game")
            .data-label
              strong.fs-6.lh-1 Platforms
            .d-block.flex-grow-1
              GameDetailPlatforms(
                :platforms="game.platforms"
                :disabled="!isEditMode"
                @change="changePlatforms"
              )
          .d-flex.mb-2.pb-2.border-bottom(v-if="game")
            .data-label
              strong.fs-6.lh-1 SDK version
            .d-block.flex-grow-1
              b-form-select(
                v-model="game.sdk_version"
                :disabled="!isEditMode"
                :options="sdkVersionsList"
              )
          .d-flex.mb-2.pb-2.border-bottom(v-if="game")
            .data-label
              strong.fs-6.lh-1 Rates
              .d-block.pt-3
                b-form-checkbox(
                  v-model="enableEditRates"
                  name="enable-edit-rates"
                  :disabled="!isEditMode"
                ) Enable edit rates
            .d-block.flex-grow-1
              GameDetailRates(
                :rates="localRates"
                :isDisabled="!enableEditRates"
              )
        GameDetailThumb(
          :gameId="selectedGame.id || null"
          :isDisabled="!isEditMode"
          @change="changePreviews"
        )
    b-alert.m-3(
      :show="dismissCountDown"
      variant="success"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    )
      p.m-0 The game data was saved successfully
    .game-actions.d-flex.justify-content-between.bg-dark.p-3
      b-button.w-25(
        variant="outline-secondary"
        :disabled="$api.loading"
        @click="$emit('closed')"
      ) Close
      b-button.w-25(
        v-if="this.selectedGame.id"
        variant="outline-danger"
        :disabled="$api.loading"
        @click="deleteGameModalOpened = true"
      ) Delete game
      b-button.w-25(
        variant="success"
        :disabled="$api.loading || (!inputIdError && isNewGame)"
        @click="clickEdit"
      ) {{ isEditMode ? 'Save changes' : 'Edit' }}
    b-modal(
      v-model="deleteGameModalOpened"
      hide-header
      ok-variant="outline-success"
      cancel-variant="outline-danger"
      ok-title="Delete"
      @ok="deleteGame"
    )
      p.fs-6.mb-2.text-center Are you sure you want to delete this game?
    b-modal(
      v-if="game"
      v-model="editContentModalOpened"
      size="xl"
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      ok-only
      ok-variant="outline-danger"
      ok-title="Close"
      @ok="editContentModalOpened = false"
    )
      GameContentEditor(:gameId="game.id")
</template>

<script>
  import GameDetailTags from "./GameDetailTags";
  import GameDetailThumb from "./GameDetailThumb";
  import GameContentEditor from "./GameContentEditor";
  import GameDetailPlatforms from "./GameDetailPlatforms";
  import GameDetailRates from "./GameDetailRates";

  import gameStatuses from "../libs/gameStatuses";
  import categoriesList from "../libs/categories";
  import sdkVersionsList from "../libs/sdk_versions";

  export default {
    name: "GameDetail",
    components: {
      GameDetailTags,
      GameDetailThumb,
      GameContentEditor,
      GameDetailPlatforms,
      GameDetailRates
    },
    props: {
      selectedGame: Object
    },
    computed: {
      gameDataRequest() {
        return {
          id: this.game.id,
          aspect_ratio: this.game.aspect_ratio,
          category: this.game.category,
          description: this.game.description,
          sdk_version: this.game.sdk_version,
          slug: this.game.slug,
          src: this.game.src,
          status: this.game.status,
          title: this.game.title
        };
      },
      inputIdError() {
        if (this.isNewGame && this.isValidId) return true;
        return this.isValidId;
      }
    },
    data() {
      return {
        game: null,
        isEditMode: false,
        isNewGame: false,
        isValidId: null,
        enableEditRates: false,
        currentTagList: [],
        previewsList: [],
        platformList: [],
        localRates: {
          likes_count: 0,
          bookmarks_count: 0,
          rating_1_count: 0,
          rating_2_count: 0,
          rating_3_count: 0,
          rating_4_count: 0,
          rating_5_count: 0
        },
        deleteGameModalOpened: false,
        editContentModalOpened: false,
        dismissSecs: 5,
        dismissCountDown: 0,
        checkIdTimeout: null,
        categoriesList,
        sdkVersionsList,
        gameStatuses
      };
    },
    mounted() {
      if (!this.selectedGame.id) {
        this.game = {
          id: null,
          aspect_ratio: null,
          category: "",
          description: "",
          sdk_version: null,
          slug: "",
          src: "",
          status: "",
          title: "",
          tags: []
        };
        this.isEditMode = true;
        this.isNewGame = true;
        return;
      }
      this.getGame();
    },
    methods: {
      getGame() {
        this.$api
          .authorize(this.$firebase.getToken())
          .get(`/games/`, {
            params: {
              ids: this.selectedGame.id.toString(),
              status: "ALL",
              with_tags: true,
              with_rates: true,
              with_platforms: true
            }
          })
          .then((data) => {
            this.game = data[0];
            this.setLocalRates();
          })
          .catch((e) => {
            console.log(e);
          });
      },
      setLocalRates() {
        if (!this.game || this.isNewGame) return;
        this.localRates.likes_count = this.game.rates.total_liked;
        this.localRates.bookmarks_count = this.game.rates.total_bookmarked;
      },
      checkGameId() {
        if (!this.isNewGame) return;
        this.$api
          .authorize(this.$firebase.getToken())
          .get(`/games/`, {
            params: {
              ids: this.game.id.toString(),
              status: "ALL"
            }
          })
          .then((data) => {
            if (data.length) {
              this.isValidId = false;
              return;
            }
            this.isValidId = true;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      validateGameId() {
        if (!this.isNewGame) return;
        clearTimeout(this.checkIdTimeout);
        this.checkIdTimeout = setTimeout(() => {
          this.checkGameId();
        }, 600);
      },
      setGame() {
        return this.$api
          .authorize(this.$firebase.getToken())
          .put(`/games/${this.selectedGame.id}/`, this.gameDataRequest)
          .then(() => {
            console.log("saved");
          })
          .catch((e) => {
            console.log(e);
          });
      },
      addGame() {
        return this.$api
          .authorize(this.$firebase.getToken())
          .post(`/games/`, this.gameDataRequest)
          .catch((e) => {
            console.log(e);
          });
      },
      saveGameTags() {
        if (!this.currentTagList.length) return;
        return this.$api
          .authorize(this.$firebase.getToken())
          .put(`/games/${this.game.id}/tags/`, {
            game_id: this.game.id,
            tag_ids: this.currentTagList
          })
          .catch((e) => {
            console.log(e);
          });
      },
      clickEdit() {
        if (!this.isEditMode) {
          this.isEditMode = true;
          return;
        }
        this.save();
      },
      changeTagList(data) {
        this.currentTagList = data;
      },
      changePreviews(data) {
        this.previewsList.push(data);
      },
      setGameRates() {
        if (!this.enableEditRates) return;
        return this.$api
          .authorize(this.$firebase.getToken())
          .put(`/games/${this.game.id}/reactions/set/`, {
            ...this.localRates
          })
          .then(() => {
            this.enableEditRates = false;
          })
          .catch((e) => {
            console.log(e);
          });
      },
      setPreview({ type, file }) {
        if (!this.previewsList.length) return;

        const formData = new FormData();
        formData.append("type", type.slice(0, -5));
        formData.append("image", file, file.name);

        return this.$api
          .authorize(this.$firebase.getToken())
          .post(`/games/${this.game.id}/thumbs/`, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .catch((e) => {
            console.log(e);
          });
      },
      changePlatforms(data) {
        this.platformList = data;
      },
      setPlatforms() {
        if (!this.platformList.length) return;
        return this.$api
          .authorize(this.$firebase.getToken())
          .put(`/games/${this.game.id}/platforms/`, {
            platforms: this.platformList.join()
          })
          .catch((e) => {
            console.log(e);
          });
      },
      async save() {
        this.trimParams();
        this.isEditMode = false;
        if (!this.selectedGame.id) {
          await this.addGame();
        } else {
          await this.setGame();
        }
        await this.saveGameTags();
        await this.setPlatforms();
        await this.setGameRates();

        for (const preview of this.previewsList) {
          await this.setPreview(preview);
        }

        this.$emit("saved", this.gameDataRequest);
        this.showAlert();
      },
      trimParams() {
        this.game = {
          ...this.game,
          description: this.game.description.trim(),
          slug: this.game.slug.trim(),
          src: this.game.src.trim(),
          title: this.game.title.trim()
        }
      },
      deleteGame() {
        this.deleteGameModalOpened = false;
        this.$emit("delete", this.selectedGame.id);
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown;
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs;
      },
      fillSlug() {
        this.game.slug = this.game.title.toLowerCase().replace(/\s/gi, "-");
      }
    }
  };
</script>

<style scoped lang="scss">
  .game-data {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .data-label {
    width: 25%;
    min-width: 25%;
  }

  .game-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  .game-body {
    padding-bottom: 86px !important;
  }
</style>
