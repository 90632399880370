<template lang="pug">
  PageStructure
    .d-flex.pb-3.mb-3.border-bottom
      b-input-group
        b-form-input(
          v-model="filter.mail"
          placeholder="Enter user nickname or mail"
        )
        b-form-select.form-select(
          v-model="filter.status"
          :options="userRoleList"
        )
        b-button(variant="outline-danger" @click="resetFilter") Reset
    b-table-simple(
      striped
      hover
      small
    )
      b-thead
        b-tr
          b-th Logo
          b-th Mail
          b-th NickName
          b-th ID
          b-th Status
          b-th
      b-tbody
        b-tr(
          v-for="user in userList"
          :key="user.id"
        )
          b-td.align-middle
            b-avatar(:src="user.logo")
          b-td.align-middle {{ user.mail }}
          b-td.align-middle {{ user.nickname }}
          b-td.align-middle {{ user.id }}
          b-td.align-middle {{ user.status }}
          b-td.align-middle
            b-button(
              variant="outline-primary"
              size="sm"
              @click="selectUser(user)"
            )
              b-icon(icon="gear-fill")
    ShowMoreButton(
      v-model="pagination"
      :isLoading="$api.loading"
      @changePagination="getUserList()"
    )
    b-sidebar(
      v-model="isOpenedUserDetail"
      width="40vw"
      right
      shadow
      no-header
      no-close-on-backdrop
      backdrop-variant="secondary"
      backdrop
    )
      b-overlay(
        :show="$api.loading"
        spinner-variant="success"
      )
        UserDetailEditor(v-model="selectedUser")
      template(#footer)
        .d-flex.justify-content-between.bg-dark.p-3
          b-button.w-25(variant="outline-secondary" :disabled="$api.loading" @click="cancelSelectUser") Cancel
          b-button.w-25(v-b-modal.modal-delete-user variant="danger" :disabled="$api.loading") Delete user
          b-button.w-25(variant="success" :disabled="$api.loading" @click="saveSelectedUser") Save changes
    b-modal(
      id="modal-delete-user"
      hide-header
      ok-variant="outline-success"
      cancel-variant="outline-danger"
      ok-title="Delete"
      @ok="deleteUserHandler"
    )
      p.fs-6.mb-2.text-center Are you sure you want to delete this user?
</template>

<script>
  import PageStructure from "../components/PageStructure";
  import UserDetailEditor from "../components/UserDetailEditor";
  import ShowMoreButton from "../components/ShowMoreButton";

  export default {
    name: "UsersPage",
    components: { PageStructure, UserDetailEditor, ShowMoreButton },
    data() {
      return {
        userList: [],
        userListOriginal: [],
        isOpenedUserDetail: false,
        selectedUserOriginal: null,
        selectedUser: null,
        filter: {
          status: null,
          mail: null
        },
        userRoleList: [
          { value: null, text: 'ALL USER ROLES' },
          "ADMIN", "USER", "STAFF", "READER"
        ],
        pagination: {
          limit: 50,
          offset: 0,
          total: 0
        }
      };
    },
    watch: {
      "filter.status"() {
        this.pagination.offset = 0;
        this.getUserList(true);
      },
      "filter.mail"() {
        this.pagination.offset = 0;
        this.getUserList(true);
      }
    },
    mounted() {
      this.getUserList();
    },
    methods: {
      getUserList(isNewRequest) {
        this.$api
          .authorize(this.$firebase.getToken())
          .get("/users/", {
            params: {
              limit: this.pagination.limit,
              offset: this.pagination.offset,
              order: "registered_at",
              status: this.filter.status,
              mail: this.filter.mail ? `%${this.filter.mail}%` : null
            }
          })
          .then((data) => {
            this.pagination.total = this.$api.meta.total;
            this.userList = isNewRequest ? data : [...this.userList, ...data];
            this.userListOriginal = isNewRequest ? data : [...this.userListOriginal, ...data];
          });
      },
      selectUser(user) {
        this.selectedUser = user;
        this.selectedUserOriginal = { ...user };
        this.isOpenedUserDetail = true;
      },
      unsetSelectUser() {
        this.isOpenedUserDetail = false;
        this.selectedUserOriginal = null;
        this.selectedUser = null;
      },
      cancelSelectUser() {
        Object.keys(this.selectedUser).forEach((key) => {
          this.selectedUser[key] = this.selectedUserOriginal[key];
        });
        this.unsetSelectUser();
      },
      async saveSelectedUser() {
        const { id, name, nickname, logo, status } = this.selectedUser;
        const params = {
          name,
          nickname,
          status,
          logo: this.selectedUser.logoFile ? null : logo
        };

        if (this.selectedUser.logoFile) {
          const formData = new FormData();
          formData.append(
            "avatar",
            this.selectedUser.logoFile,
            this.selectedUser.logoFile["name"]
          );
          await this.$api
            .authorize(this.$firebase.getToken())
            .post(`/users/${id}/logo/`, formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            });
        }

        await this.$api
          .authorize(this.$firebase.getToken())
          .put(`/users/${id}/`, params)
          .then(() => {
            this.unsetSelectUser();
          });
      },
      deleteUserHandler() {
        const selectedUserIndex = this.userList.findIndex(
          (user) => user.id === this.selectedUser.id
        );

        this.$api
          .authorize(this.$firebase.getToken())
          .delete(`/users/${this.selectedUser.id}/`)
          .then(() => {
            this.unsetSelectUser();
            this.userList.splice(selectedUserIndex, 1);
          });
      },
      resetFilter() {
        this.filter = {
          status: null,
          mail: null
        }
      }
    }
  };
</script>
