import Vue from "vue";

import timeSince from "./timeSince";
import toKMB from "./toKMB";
import textToHtml from "./textToHtml";
import uuid from "./uuid";
import randomString from "./randomString";
import randomDigits from "./randomDigits";
import camelToSake from "./camelToSake";
import isMdDownScreen from "./isMdDownScreen";
import isLgDownScreen from "./isLgDownScreen";
import localeString from "./localeString";
import sortQuery from "./sortQuery";
import setBodyOverflow from "./setBodyOverflow";
import changeQueryParam from "./changeQueryParam";
import isNotSupportedFullScreenApi from "./isNotSupportedFullScreenApi";
import getCookie from "./getCookie";
import setCookie from "./setCookie";
import timeFormatted from "./timeFormatted";
import getGameStatusColorClass from "./getGameStatusColorClass";

Vue.prototype.$helpers = {
  timeSince,
  toKMB,
  textToHtml,
  uuid,
  randomString,
  randomDigits,
  camelToSake,
  isMdDownScreen,
  isLgDownScreen,
  localeString,
  sortQuery,
  setBodyOverflow,
  changeQueryParam,
  isNotSupportedFullScreenApi,
  getCookie,
  setCookie,
  timeFormatted,
  getGameStatusColorClass
};
