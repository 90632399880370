<template lang="pug">
  .d-block
    .d-flex.mb-3
      .d-flex.me-3.flex-column.w-25
        b Likes
        b-form-spinbutton(
          v-model="rates.likes_count"
          min="0"
          max="1000000"
          step="1"
          :disabled="isDisabled"
          inline
        )
      .d-flex.flex-column
        b Bookmarked
        b-form-spinbutton(
          v-model="rates.bookmarks_count"
          min="0"
          max="1000000"
          step="1"
          :disabled="isDisabled"
          inline
        )
    .d-block.border-top.pt-3
      .d-flex.align-items-center.mb-2
        label.me-3.w-25 Rate 1 stars
        b-form-spinbutton(
          v-model="rates.rating_1_count"
          min="0"
          max="1000000"
          step="1"
          :disabled="isDisabled"
          inline
        )
      .d-flex.align-items-center.mb-2
        label.me-3.w-25 Rate 2 stars
        b-form-spinbutton(
          v-model="rates.rating_2_count"
          min="0"
          max="1000000"
          step="1"
          :disabled="isDisabled"
          inline
        )
      .d-flex.align-items-center.mb-2
        label.me-3.w-25 Rate 3 stars
        b-form-spinbutton(
          v-model="rates.rating_3_count"
          min="0"
          max="1000000"
          step="1"
          :disabled="isDisabled"
          inline
        )
      .d-flex.align-items-center.mb-2
        label.me-3.w-25 Rate 4 stars
        b-form-spinbutton(
          v-model="rates.rating_4_count"
          min="0"
          max="1000000"
          step="1"
          :disabled="isDisabled"
          inline
        )
      .d-flex.align-items-center.mb-2
        label.me-3.w-25 Rate 5 stars
        b-form-spinbutton(
          v-model="rates.rating_5_count"
          min="0"
          max="1000000"
          step="1"
          :disabled="isDisabled"
          inline
        )
</template>

<script>
  export default {
    name: "GameDetailRates",
    props: {
      rates: Object,
      isDisabled: Boolean
    }
  };
</script>

