<template lang="pug">
  .d-block
    .d-flex.mb-3
      .d-block.pe-3.w-25
        .d-block Default Image
        input.input-file-field(ref="defaultThumb" type="file" accept="image/*" @change="onFileSelect('defaultThumb')")
        b-button(variant="outline-success" :disabled="isDisabled" @click="$refs.defaultThumb.click()") Set image
      .d-block.w-75
        .d-block.text-danger(v-if="!defaultSrc") No image
        b-img.w-100(v-else :src="defaultSrc")
    .d-flex.mb-3
      .d-block.pe-3.w-25
        .d-block Animate Image
        input.input-file-field(ref="animateThumb" type="file" accept="image/*" @change="onFileSelect('animateThumb')")
        b-button(variant="outline-success" :disabled="isDisabled" @click="$refs.animateThumb.click()") Set image
      .d-block.w-75
        .d-block.text-danger(v-if="!animateSrc") No image
        b-img.w-100(v-else :src="animateSrc")
    .d-flex.mb-3
      .d-block.pe-3.w-25
        .d-block Square Image
        input.input-file-field(ref="squareThumb" type="file" accept="image/*" @change="onFileSelect('squareThumb')")
        b-button(variant="outline-success" :disabled="isDisabled" @click="$refs.squareThumb.click()") Set image
      .d-block.w-75
        .d-block.text-danger(v-if="!squareSrc") No image
        b-img.w-100(v-else :src="squareSrc")
</template>

<script>
  export default {
    name: "GameDetailThumb",
    props: {
      gameId: [ Number, String ],
      isDisabled: Boolean
    },
    computed: {
      defaultSrc() {
        return this.defaultThumb;
      },
      animateSrc() {
        return this.animateThumb;
      },
      squareSrc() {
        return this.squareThumb;
      }
    },
    data() {
      return {
        thumbs: null,
        defaultThumb: null,
        animateThumb: null,
        squareThumb: null
      };
    },
    mounted() {
      this.getImgSrc("default.sm.jpg", "defaultThumb");
      this.getImgSrc("animate.sm.gif", "animateThumb");
      this.getImgSrc("square.sm.jpg", "squareThumb");
    },
    methods: {
      checkImageFound(imgName) {
        const img = new Image();
        img.src = `${process.env.VUE_APP_API_HOST}/data/${this.gameId}/thumbs/${imgName}?v=${+new Date()}`;
        return new Promise((res, rej) => {
          img.onload = () => res(img.src);
          img.onerror = rej;
        });
      },
      getImgSrc(imgName, imgVar) {
        this.checkImageFound(imgName)
        .then((src) => {
          this[imgVar] = src;
        })
        .catch(() => {
          this[imgVar] = null;
        });
      },
      onFileSelect(type) {
        const reader = new FileReader();
        const file = this.$refs[type].files[0];
        reader.onload = (e) => {
          this[type] = e.target.result;
        };
        reader.readAsDataURL(file);
        this.$emit("change", { type, file });
      }
    }
  };
</script>

<style scoped lang="scss">
  .input-file-field {
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
  }
</style>
