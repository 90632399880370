<template lang="pug">
  .d-block
    .d-flex.align-items-start.border-bottom(:class="comment.replied_to_id ? 'comment_inner mt-1' : ''")
      .d-block.py-2.me-3
        b-avatar(
          :src="comment.user && comment.user.logo"
          :size="comment.replied_to_id ? 'xs' : 'md'"
        )
      .d-block.py-2.pl-2.flex-grow-1
        .d-flex.align-items-center.mb-2
          span.fw-bold.small.me-3 {{comment.user.nickname}}
          span.fw-light.small.me-3 {{$helpers.timeSince(comment.created_at)}}
          span.fw-light.small.me-3(v-if="comment.strikes") Strikes:
            span.fw-bold.text-danger.ms-1 {{ comment.strikes }}
          span.fw-light.small.me-3 GameId:
            span.fw-bold.ms-1.text-info {{ comment.game_id }}
          span.fw-light.small.me-3 Status:
            span.fw-bold.ms-1(:class="setGameStatusClass(comment.status)") {{ comment.status }}
        .d-block.fw-light.fs-6 {{comment.message}}
      .d-flex.py-2.ps-3
        b-button-group
          b-button(
            v-if="!comment.is_reviewed"
            variant="outline-success"
            @click="$emit('review')"
          )
            b-icon(icon="check2-all")
          b-button(
            :variant="comment.status === 'PROHIBITED' ? 'outline-success' : 'outline-warning'"
            @click="$emit('lock')"
          )
            b-icon(:icon="comment.status === 'PROHIBITED' ? 'unlock-fill' : 'lock-fill'")
          b-button(variant="outline-danger" @click="$emit('remove')")
            b-icon(icon="trash")
    .d-block.ps-5
      slot
</template>

<script>
  export default {
    name: "Comment",
    props: {
      comment: Object
    },
    methods: {
      setGameStatusClass(val) {
        if (!val) return "";
        return val === "ENABLED"
          ? "text-success"
          : val === "PAUSED"
            ? "text-warning"
            : val === "PROHIBITED"
              ? "text-info"
              : "text-danger";
      }
    }
  };
</script>
