<template lang="pug">
  .d-block.w-100
    b-navbar(
      type="dark"
      variant="dark"
    )
      b-collapse(is-nav)
        b-navbar-nav
          b-nav-item(
            v-for="menuItem in menuList"
            :key="menuItem.routeName"
            :to="{ name: $routerLinksPages[menuItem.routeName] }"
            :active="menuItem.routeName === $route.name"
          ) {{ menuItem.routeTitle }}
        b-navbar-nav.ml-auto
          b-nav-text
            LoggedInUserInfo
</template>

<script>
  import LoggedInUserInfo from "../components/LoggedInUserInfo";

  export default {
    name: "TopMenu",
    components: { LoggedInUserInfo },
    data() {
      return {
        menuList: [
          {
            routeName: "GamesPage",
            routeTitle: "Games"
          },
          {
            routeName: "TagsPage",
            routeTitle: "Tags"
          },
          {
            routeName: "OptionsPage",
            routeTitle: "Options"
          },
          {
            routeName: "TrackersPage",
            routeTitle: "Trackers"
          },
          {
            routeName: "UsersPage",
            routeTitle: "Users"
          },
          {
            routeName: "CommentsPage",
            routeTitle: "Comments"
          }
        ]
      }
    }
  };
</script>
