<template lang="pug">
  .d-flex.flex-column(style="min-height: 100vh")
    router-view
</template>

<script>
  export default {
    mounted() {
      if (!this.$user || (this.$user && this.$user.status !== "ADMIN")) {
        if(this.$route.name !== this.$routerLinksPages.LoginPage) {
          this.$router.push({ name: this.$routerLinksPages.LoginPage });
        }
      }
    }
  };
</script>
