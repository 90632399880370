<template lang="pug">
  PageStructure
    b-table-simple(striped hover)
      b-thead
        b-tr
          b-th Option name
          b-th Option value
          b-th
      b-tbody
        b-tr(v-for="(option, index) in optionsList" :key="option.name")
          b-td.align-middle.text-nowrap.pe-4 {{ option.name }}
          b-td.align-middle.w-100
            b-form-input(
              v-if="option.name === editedOptionId"
              v-model="option.value"
              :readonly="$api.loading"
              type="text"
            )
            span(v-else) {{ option.value }}
          b-td
            .d-block(v-if="option.name === editedOptionId")
              b-overlay(
                :show="$api.loading"
                spinner-small
                spinner-variant="success"
              )
                .d-flex.justify-content-end
                  b-button.me-2(variant="outline-secondary" size="sm" @click="cancelEdit(index)") Cancel
                  b-button(variant="outline-success" size="sm" @click="setOptions(option.name, option.value)") Save
            .d-flex.justify-content-end(v-else)
              b-button(
                variant="outline-primary"
                size="sm"
                :disabled="editedOptionId !== null"
                @click="openEdit(option.name, option.value)"
              )
                b-icon(icon="gear-fill")
    b-overlay(
      v-if="!optionsList"
      :show="$api.loading"
      spinner-variant="primary"
    )
      .d-flex.p-3.justify-content-center Content loading...
</template>

<script>
  import PageStructure from "../components/PageStructure";

  export default {
    name: "OptionsPage",
    components: { PageStructure },
    data() {
      return {
        optionsList: null,
        editedOriginalValue: null,
        editedOptionId: null
      }
    },
    mounted() {
      this.getOptions();
    },
    methods: {
      getOptions() {
        this.$api
          .authorize(this.$firebase.getToken())
          .get("/options/")
          .then((data) => {
            this.optionsList = Object.keys(data).map((name, i) => {
              return {
                name,
                value: Object.values(data)[i]
              }
            });
          });
      },
      setOptions(option_id, value) {
        this.$api
          .authorize(this.$firebase.getToken())
          .put(`/options/${option_id}/`, {
            option_id,
            value
          })
          .then(() => {
            this.editedOptionId = null;
            this.editedOriginalValue = null;
          });
      },
      openEdit(option_id, value) {
        this.editedOptionId = option_id;
        this.editedOriginalValue = value;
      },
      cancelEdit(index) {
        this.editedOptionId = null;
        this.optionsList[index].value = this.editedOriginalValue;
        this.editedOriginalValue = null;
      }
    }
  };
</script>
