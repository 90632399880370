import router from "../plugins/router";

export default function changeQueryParam(
  paramName,
  paramValue,
  replace = false
) {
  let query = { ...router.currentRoute.query };
  if (paramValue === null) {
    paramValue = undefined;
  }
  if (query[paramName] === paramValue) {
    return;
  }
  if (paramValue === undefined) {
    delete query[paramName];
  } else {
    query[paramName] = paramValue;
  }
  if (replace) {
    router.replace({ query });
  } else {
    router.push({ query });
  }
}
