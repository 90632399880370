export default function timeSince(date) {
  if (Number.isInteger(date)) {
    date = new Date(date < 4294967295 ? date * 1000 : date);
  }
  let seconds = Math.floor((new Date() - date) / 1000);
  if (seconds > 946080000) {
    return "never";
  }
  let periods = [
    ["year", 31536000],
    ["month", 2592000],
    ["day", 86400],
    ["hour", 3600],
    ["min", 60],
    ["sec", 1]
  ];
  for (let period of periods) {
    if (seconds < period[1]) continue;
    let interval = Math.floor(seconds / period[1]);
    return `${interval} ${period[0]}${interval > 1 ? "s" : ""} ago`;
  }
  return `now`;
}
