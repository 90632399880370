import axios from "axios";
import Vue from "vue";

class Firebase {
  fieldMap = {
    id: (user) => user.localId,
    name: (user) => user.displayName,
    mail: (user) => user.email,
    logo: (user) => user.photoUrl,
    is_verified: (user) => user.emailVerified,
    provider: (user) => {
      if (
        !user.providerUserInfo ||
        !user.providerUserInfo.length ||
        !user.providerUserInfo[0].providerId
      ) {
        return undefined;
      }
      return user.providerUserInfo[0].providerId;
    },
    registered_at: (user) =>
      user.createdAt ? Math.round(parseInt(user.createdAt) / 1000) : undefined,
    seen_at: () => Math.round(Date.now() / 1000),
    access_token: (user) => user.access_token || user.idToken,
    refresh_token: (user) => user.refresh_token || user.refreshToken,
    expires_at: (user) => {
      let expiresIn = user.expires_in || user.expiresIn;
      if (!expiresIn) return undefined;
      return Math.round(Date.now() / 1000) + parseInt(expiresIn);
    }
  };

  constructor(apiKey = null) {
    this.apiKey = apiKey || process.env.VUE_APP_FIREBASE_API_KEY;
    this.storageUserKey =
      process.env.VUE_APP_FIREBASE_STORAGE_USER_KEY || "firebase_user";
    this.storageSessionKey =
      process.env.VUE_APP_FIREBASE_STORAGE_SESSION_KEY || "firebase_session";
    this.listeners = [];
    this.tokenRequest = null;
    this.store = Vue.observable({
      user: JSON.parse(localStorage.getItem(this.storageUserKey)),
      loading: false,
      error: null
    });

    Object.defineProperty(this, "user", {
      get: function () {
        return this.store.user;
      }
    });

    Object.defineProperty(this, "loading", {
      get: function () {
        return this.store.loading;
      }
    });

    Object.defineProperty(this, "error", {
      get: function () {
        return this.store.error;
      }
    });

    if (location.href.match(/[&?]authRedirect=1/)) {
      this._handleRedirects();
    } else if (this.user) {
      this.refreshProfile();
    }
  }

  _setUser(user, replace = false) {
    if (user) {
      const newUser =
        (!replace && JSON.parse(JSON.stringify(this.store.user))) || {};
      for (const [field, func] of Object.entries(this.fieldMap)) {
        const value = func(user);
        if (value !== undefined) {
          newUser[field] = value;
        }
      }
      if (user.customAttributes !== undefined) {
        for (const [field, value] of Object.entries(
          JSON.parse(user.customAttributes)
        )) {
          newUser[field] = value;
        }
      }
      this.store.user = newUser;
      localStorage.setItem(this.storageUserKey, JSON.stringify(newUser));
    } else {
      this.store.user = null;
      localStorage.removeItem(this.storageUserKey);
    }
    for (let listener of this.listeners) {
      listener(this.store.user);
    }
  }

  async _callApi(endpoint, params) {
    const checkError = (response) => {
      if (!response) {
        this.store.error = {
          code: "NETWORK_ERROR",
          message: "Network problem. Check your internet connection"
        };
      }
      if (!response.data) {
        this.store.error = {
          code: "FIREBASE_API_ERROR",
          message: "Format error in firebase api answer. Please try again later"
        };
      }
      if (response.data.error) {
        const code = response.data.error.message.replace(
          / ?: [\w ,.'"()]+$/,
          ""
        );
        const message =
          response.data.error.message
            .replace(code, "")
            .replace(/^[ ,.'"():]+/, "") || code;
        this.store.error = {
          code: code,
          message: message
        };
      }
      if (this.store.error) {
        throw Error(this.store.error.code);
      }
    };

    this.store.loading = true;
    this.store.error = null;
    return axios
      .post(
        `https://identitytoolkit.googleapis.com/v1/accounts:${endpoint}?key=${this.apiKey}`,
        params
      )
      .then((response) => {
        checkError(response);
        return response.data;
      })
      .catch((error) => {
        checkError(error.response);
      })
      .finally(() => {
        this.store.loading = false;
      });
  }

  async _handleRedirects() {
    const href = location.href;
    history.replaceState(null, null, location.origin + location.pathname);

    if (href.match(/[&?]code=/)) {
      const sessionId = localStorage.getItem(this.storageSessionKey);
      if (!sessionId) {
        return;
      }
      const user = await this._callApi("signInWithIdp", {
        requestUri: href,
        returnSecureToken: true,
        sessionId
      });
      localStorage.removeItem(this.storageSessionKey);
      this._setUser(
        {
          localId: user.localId,
          refreshToken: user.refreshToken
        },
        true
      );
      return this.refreshProfile();
    }
  }

  onUserChange(callback) {
    this.listeners.push(callback);
    return () => {
      this.listeners = this.listeners.filter(
        (listener) => listener !== callback
      );
    };
  }

  async getToken(forceRefresh = false) {
    if (!this.user) {
      this.store.error = {
        code: "NOT_SIGNED_IN",
        message: "Please sign in to refresh your profile"
      };
      throw Error("NOT_SIGNED_IN");
    }
    if (
      !forceRefresh &&
      this.user.access_token &&
      Date.now() / 1000 < this.user.expires_at
    ) {
      return this.user.access_token;
    }
    if (this.tokenRequest) {
      return this.tokenRequest;
    }
    this.tokenRequest = axios
      .post(`https://securetoken.googleapis.com/v1/token?key=${this.apiKey}`, {
        grant_type: "refresh_token",
        refresh_token: this.user.refresh_token
      })
      .then((response) => {
        this._setUser(response.data);
        return this.user.access_token;
      });
    return this.tokenRequest;
  }

  async refreshProfile() {
    const idToken = await this.getToken();
    const result = await this._callApi("lookup", { idToken });
    if (result.users && result.users.length) {
      this._setUser(result.users[0]);
    }
    return this.user;
  }

  async signUp(email, password, withProfile = true) {
    const user = await this._callApi("signUp", {
      email,
      password,
      returnSecureToken: true
    });
    this._setUser(user, true);
    if (withProfile) {
      await this.refreshProfile();
    }
    return this.user;
  }

  async signInWithPassword(email, password, withProfile = true) {
    const user = await this._callApi("signInWithPassword", {
      email,
      password,
      returnSecureToken: true
    });
    this._setUser(user, true);
    if (withProfile) {
      await this.refreshProfile();
    }
    return this.user;
  }

  async signInWithProvider(providerId, oauthScope = null) {
    const { authUri, sessionId } = await this._callApi("createAuthUri", {
      continueUri: `${location.protocol}//${location.host}${location.pathname}?authRedirect=1`,
      authFlowType: "CODE_FLOW",
      providerId: providerId,
      oauthScope
    });
    localStorage.setItem(this.storageSessionKey, sessionId);
    location.assign(authUri);
  }

  async sendEmailVerification() {
    const idToken = await this.getToken();
    await this._callApi("sendOobCode", {
      requestType: "VERIFY_EMAIL",
      idToken
    });
    return this.store.user;
  }

  async makeEmailVerification(code) {
    const user = await this._callApi("update", {
      oobCode: code
    });
    this._setUser(user);
    return this.store.user;
  }

  async sendPasswordReset(mail) {
    await this._callApi("sendOobCode", {
      requestType: "PASSWORD_RESET",
      email: mail
    });
    return this.store.user;
  }

  async makePasswordReset(code, password) {
    await this._callApi("resetPassword", {
      oobCode: code,
      newPassword: password
    });
    return true;
  }

  async signOut() {
    this._setUser(null);
    return null;
  }

  async deleteAccount() {
    const idToken = await this.getToken();
    await this._callApi("delete", { idToken });
    await this.signOut();
    return null;
  }
}

const firebase = new Firebase();
const user = firebase.store.user;

Object.defineProperty(Vue.prototype, "$firebase", {
  get: function () {
    return firebase;
  }
});

Object.defineProperty(Vue.prototype, "$user", {
  get: function () {
    return firebase.store.user;
  }
});

export { firebase, user };
