<template lang="pug">
  .d-block.p-3(v-if="user")
    .d-block.mb-4.pb-4.border-bottom
      .d-flex.align-items-center.justify-content-between.mb-3
        b-avatar.me-3(:src="user.logo" size="6rem" @click="$refs.avatarInput.click()")
        b-form-radio-group(
          buttons
        )
          b-form-radio(
            v-model="isAvatarUrlEdit"
            size="lg"
            :value="false"
          )
            span.ms-2 Load file
          b-form-radio(
            v-model="isAvatarUrlEdit"
            size="lg"
            :value="true"
          )
            span.ms-2 Set URL
      .d-flex.align-items-center(v-if="isAvatarUrlEdit !== null")
        .d-flex.align-items-center(v-if="!isAvatarUrlEdit")
          input.input-file-field(ref="avatarInput" type="file" accept="image/*" @change="onFileSelect")
          b-button(variant="outline-success" @click="$refs.avatarInput.click()") Set image
        b-form-input.form-input(v-else v-model="user.logo" type="text")
    .d-flex.align-items-center.mb-3
      .user-data-label.fw-bold ID:
      .d-block {{ user.id }}
    .d-flex.align-items-center.mb-3
      .user-data-label.fw-bold Mail:
      .d-block {{ user.mail }}
    .d-flex.align-items-center.mb-3
      .user-data-label.fw-bold Name:
      .d-block
        b-form-input.form-input(v-model="user.name" type="text")
    .d-flex.align-items-center.mb-3
      .user-data-label.fw-bold Nickname:
      .d-block
        b-form-input.form-input(v-model="user.nickname" type="text")
    .d-flex.align-items-center.mb-3
      .user-data-label.fw-bold Registered at:
      .d-block {{ $helpers.timeFormatted(user.registered_at) }}
    .d-flex.align-items-center.mb-3
      .user-data-label.fw-bold Seen at:
      .d-block {{ $helpers.timeFormatted(user.seen_at) }}
    .d-flex.align-items-center
      .user-data-label.fw-bold Status:
      .d-block
        b-form-select.form-select(v-model="user.status" :options="statusSelectVariants")
</template>

<script>
  export default {
    name: "UserDetailEditor",
    model: {
      prop: "user",
      event: "change"
    },
    props: {
      user: Object
    },
    data() {
      return {
        isAvatarUrlEdit: null,
        statusSelectVariants: [
          { value: "USER", text: 'USER' },
          { value: "STAFF", text: 'STAFF' },
          { value: "READER", text: 'READER' },
          { value: "ADMIN", text: 'ADMIN' }
        ]
      }
    },
    methods: {
      onFileSelect() {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.user.logo = e.target.result;
        };
        reader.readAsDataURL(this.$refs.avatarInput.files[0]);
        this.user.logoFile = this.$refs.avatarInput.files[0];
      }
    }
  };
</script>

<style scoped lang="scss">
  .user-data-label {
    width: 8rem;
  }

  .input-file-field {
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
  }
</style>
