import Vue from "vue";
import VueRouter from "vue-router";
import routes from "../views/routes";

Vue.use(VueRouter);

Vue.prototype.$routerLinksPages = {};

const finalRoutes = [];
for (let route of routes) {
  if (route.path === "*") {
    finalRoutes.push(route);
    continue;
  }
  Vue.prototype.$routerLinksPages[route.name] = route.name;
  route.pathToRegexpOptions = { strict: true };
  if (route.path.slice(-1) !== "/") {
    route.path += "/";
  }
  finalRoutes.push(route);
}

const router = new VueRouter({
  mode: "history",
  routes: finalRoutes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
