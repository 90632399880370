<template lang="pug">
  .d-block
    b-form-checkbox-group(
      v-model="selected"
      :options="options"
      :disabled="disabled"
      name="platforms"
      @change="val => $emit('change', val)"
    )
</template>

<script>
  export default {
    name: "GameDetailPlatforms",
    props: {
      disabled: Boolean,
      platforms: [Array, Object]
    },
    data() {
      return {
        selected: [],
        options: ["MOBILE", "TABLET", "DESKTOP"]
      }
    },
    mounted() {
      this.selected = this.platforms;
    }
  };
</script>
