<template lang="pug">
  .d-block
    .d-flex.flex-wrap
      TagLabel(
        v-for="tag in tagList"
        :key="tag.id"
        :tag="tag"
        :disabled="disabled"
        @remove="remove(tag.id)"
      )
    .d-flex
      b-form-select(
        v-model="selectedTag"
        :disabled="disabled"
        :options="tagListOptions"
        @change="add"
      )
    .d-flex.mt-3
      .d-block.flex-grow-1
        b-form-input(
          v-model="newTag"
          :disabled="disabled"
          placeholder="Enter new tag"
        )
      .d-block.ps-3
        b-button(
          variant="outline-success"
          :disabled="disabled"
          @click="addNewTag"
        ) Add new tag
</template>

<script>
  import TagLabel from "./TagLabel";

  export default {
    name: "GameDetailTags",
    components: { TagLabel },
    props: {
      tags: Array,
      disabled: Boolean
    },
    computed: {
      tagListOptions() {
        const selectedTagIds = this.tagList.map((tag) => tag.id);
        const filteredTagList = this.$store.tags.filter((tag) =>
          !selectedTagIds.includes(tag.id)
        );
        return filteredTagList.map((tag) => {
          return {
            value: tag.id,
            text: tag.title
          };
        });
      }
    },
    data() {
      return {
        tagList: this.tags || [],
        selectedTag: null,
        newTag: null
      };
    },
    methods: {
      add() {
        if(!this.selectedTag) return;
        const addedTag = this.$store.tags.find(
          (tag) => tag.id === this.selectedTag
        );
        this.tagList.splice(this.tagList.length, 0, addedTag);
        this.selectedTag = null;
        this.setCurrentTags();
      },
      addNewTag() {
        const slug = this.newTag.toLowerCase().replace(/\s/gi, "-");
        this.$api
          .authorize(this.$firebase.getToken())
          .post("/tags/", {
            slug,
            title: this.newTag
          })
          .then((id) => {
            this.$store.tags.splice(this.$store.tags.length, 0, {
              id,
              slug,
              title: this.newTag
            });
            this.newTag = null;
            this.selectedTag = id;
            this.add();
          });
      },
      remove(id) {
        const removedTagIndex = this.tagList.findIndex((tag) => tag.id === id);
        this.tagList.splice(removedTagIndex, 1);
        this.setCurrentTags();
      },
      setCurrentTags() {
        this.$emit("change", this.tagList.map((tag) => tag.id).join());
      }
    }
  };
</script>
