<template lang="pug">
  PageStructure
    .d-block
      .d-block.w-50.mb-5
        b-overlay(
          :show="$api.loading"
          spinner-small
          spinner-variant="success"
        )
          b-input-group
            b-form-input(
              v-model="newTag.slug"
              :disabled="$api.loading"
              placeholder="Tag slug"
            )
            b-form-input(
              v-model="newTag.title"
              :disabled="$api.loading"
              placeholder="Tag title"
            )
            b-button(variant="success" :disabled="$api.loading" @click="addTag") Add
      .d-block.mb-3.border-bottom(v-for="letter in letterGroups")
        .d-block.fs-4.lh-2.font-weight-semi.mb-1 {{letter.firstLetter}}
        .d-flex.flex-wrap
          TagLabel(
            v-for="tag in letter.items"
            :key="tag.id"
            :tag="tag"
            :disabled="$api.loading"
            @remove="openDeleteModal"
          )
    b-modal(
      v-model="deleteTagModal"
      hide-header
      ok-variant="outline-success"
      cancel-variant="outline-danger"
      ok-title="Delete"
      @ok="deleteTag"
    )
      p.fs-6.mb-2.text-center Are you sure you want to delete this tag?
</template>

<script>
  import PageStructure from "../components/PageStructure";
  import TagLabel from "../components/TagLabel";

  export default {
    name: "TagsPage",
    components: { PageStructure, TagLabel },
    computed: {
      letterGroups() {
        let tags = this.$store.tags;
        const firstLetterList = Array.from(
          new Set(tags.map((item) => item.title[0]))
        );
        return firstLetterList.map((item) => {
          const letter = item ? { firstLetter: item } : null;
          return {
            ...letter,
            items: tags.filter((device) => device.title[0] === item)
          };
        });
      }
    },
    data() {
      return {
        deleteTagModal: false,
        deleteTagId: null,
        newTag: {
          slug: null,
          title: null
        }
      };
    },
    methods: {
      addTag() {
        this.$api
          .authorize(this.$firebase.getToken())
          .post("/tags/", {
            slug: this.newTag.slug,
            title: this.newTag.title
          })
          .then((id) => {
            this.$store.tags.splice(this.$store.tags.length, 0, {
              id,
              slug: this.newTag.slug,
              title: this.newTag.title
            });
            this.newTag = {
              slug: null,
              title: null
            };
          });
      },
      openDeleteModal(id) {
        this.deleteTagModal = true;
        this.deleteTagId = id;
      },
      deleteTag() {
        this.$api
          .authorize(this.$firebase.getToken())
          .delete(`/tags/${this.deleteTagId}/`)
          .then(() => {
            const deletedTagIndex = this.$store.tags.findIndex(
              (tag) => tag.id === this.deleteTagId
            );
            this.$store.tags.splice(deletedTagIndex, 1);
            this.deleteTagId = null;
          });
      }
    }
  };
</script>
