<template lang="pug">
  .d-block.py-3.border-bottom
    .row
      .col-1
        b-button.w-100(variant="outline-success" :disabled="isShowedGif" @click="$emit('showGif')") GIF status
      .col-1
        b-button.w-100(variant="outline-success" @click="isOpenedGifModal = true") Empty GIF
      .col-1
        b-button.w-100(variant="outline-success" @click="openIdsModal") Free ID
      .col-1
        b-button.w-100(variant="outline-success" @click="openProviders") Providers
      .col-2.offset-6
        b-button.w-100(variant="success" @click="$emit('add')") Add new game
    b-modal(
      v-model="isOpenedIdsModal"
      size="xl"
      hide-header
      ok-variant="outline-success"
      ok-title="Close"
      ok-only
    )
      .d-block.mb-3
        b-form-input(
          placeholder="Enter searchable ID"
          type="text"
          @input="findId"
        )
      .d-block {{ listIds.join(", ") }}
    b-modal(
      v-model="isOpenedGifModal"
      size="xl"
      hide-header
      ok-variant="outline-success"
      ok-title="Close"
      ok-only
    )
      b-overlay(
        :show="modalContentLoad"
        spinner-variant="success"
      )
        .d-block.mb-3(
          v-for="item in emptyGifGames"
        )
          b.d-block(
            :class="$helpers.getGameStatusColorClass(item.group)"
          ) {{ item.group }}
          .d-block {{ item.list.join(", ") }}
    b-modal(
      v-model="isOpenedProviderModal"
      size="xl"
      hide-header
      ok-variant="outline-success"
      ok-title="Close"
      ok-only
    )
      .d-block.mb-3
        b-form-input(
          placeholder="Enter searchable ID"
          type="text"
          @input="findProvider"
        )
      .d-block.mb-4(
        v-for="item in providers"
      )
        h4.d-block.text-primary {{ item.group }}
        .d-block(
          :class="!item.list.length ? 'text-secondary' : ''"
        ) {{ item.list.length ? item.list.join(", ") : "No items" }}
</template>

<script>
  export default {
    name: "GameListAdditionalOptions",
    props: {
      games: Array,
      isShowedGif: Boolean
    },
    data() {
      return {
        isOpenedIdsModal: false,
        isOpenedGifModal: false,
        isOpenedProviderModal: false,
        listIds: [],
        originalListIds: [],
        emptyGifGames: [],
        modalContentLoad: false,
        gameProviders: [],
        providers: [],
        providerGroupList: [
          {
            key: "",
            title: "GameFm"
          },
          {
            key: "famobi",
            title: "Famobi"
          },
          {
            key: "gamemonetize",
            title: "Gamemonetize"
          },
          {
            key: "gamedistribution",
            title: "Gamedistribution"
          },
          {
            key: "gamepix",
            title: "Gamepix"
          }
        ]
      };
    },
    mounted() {
      this.getEmptyGifGames();
    },
    methods: {
      showFreeIds(list) {
        const allIds = list.map((el) => el.id);
        const freeIds = [];
        const max = Math.max.apply(Math, allIds);
        const min = Math.min.apply(Math, allIds);
        for (let i = min; i < max; i++) {
          if (allIds.indexOf(i) === -1) {
            freeIds.push(i);
          }
        }
        this.listIds = freeIds;
        this.originalListIds = freeIds;
      },
      openIdsModal() {
        this.showFreeIds(this.games);
        this.isOpenedIdsModal = true;
      },
      findId(searched) {
        if (!searched.length) {
          this.listIds = this.originalListIds;
          return;
        }
        this.listIds = this.originalListIds.filter((id) => {
          return id.toString().includes(searched);
        });
      },
      getEmptyGifGames() {
        this.modalContentLoad = true;

        function checkGifImage(thumbs) {
          if (!thumbs) return false;
          const gifList = thumbs.filter((thmb) => thmb.path.includes("gif"));
          return gifList.length;
        }

        function getGamesIdGifEmpty(games) {
          return games.map((g) => g.id);
        }

        function gameStatusGroups(games) {
          const statusGroupList = Array.from(
            new Set(games.map((item) => item.status))
          ).reverse();
          return statusGroupList.map((item) => {
            const group = item ? { group: item } : null;
            return {
              ...group,
              list: getGamesIdGifEmpty(
                games.filter((device) => device.status === item)
              )
            };
          });
        }

        this.$api
          .authorize(this.$firebase.getToken())
          .get("/games/", {
            params: {
              limit: 0,
              status: "ALL",
              with_thumbs: true
            }
          })
          .then((data) => {
            const emptyGifGames = data.filter(
              (game) => !checkGifImage(game.thumbs)
            );
            this.emptyGifGames = gameStatusGroups(emptyGifGames);
            if (this.emptyGifGames.length) {
              this.modalContentLoad = false;
            }
          })
          .catch(() => {
            this.modalContentLoad = false;
          });
      },
      openProviders() {
        this.gameProviders = this.games;
        this.isOpenedProviderModal = true;
        this.getGameProviderGroups();
      },
      getGameProviderGroups() {
        this.providers = this.providerGroupList.map((group) => {
          return {
            group: group.title,
            list: this.gameProviders.filter((g) => {
              return g.src.includes(group.key) && g.id !== 0;
            }).map((g) => g.id).sort((a, b) => a - b)
          };
        });
      },
      findProvider(searched) {
        if (!searched.length) {
          this.gameProviders = this.games;
          this.getGameProviderGroups();
          return;
        }
        this.gameProviders = this.games.filter((g) => {
          return g.id.toString().includes(searched);
        });
        this.getGameProviderGroups();
      }
    }
  };
</script>
