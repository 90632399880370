<template lang="pug">
  PageStructure
    .d-block.mb-3.pb-3.border-bottom(v-for="track in trackList" :key="track.id")
      .d-flex.track-param
        .d-block.track-param-title
          strong.me-2 id:
        .d-block.flex-grow-1 {{ track.id }}
      .d-flex.track-param
        .d-block.track-param-title
          strong.me-2 place_id:
        .d-block.flex-grow-1 {{ track.place_id }}
      .d-flex.track-param
        .d-block.track-param-title
          strong.me-2 slot_code:
        .d-block.flex-grow-1 {{ track.slot_code }}
      .d-flex.track-param
        .d-block.track-param-title
          strong.me-2 slot_header:
        .d-block.flex-grow-1 {{ track.slot_header }}
</template>

<script>
  import PageStructure from "../components/PageStructure";

  export default {
    name: "TrackersPage",
    components: { PageStructure },
    data() {
      return {
        trackList: null
      };
    },
    mounted() {
      this.getTrackers();
    },
    methods: {
      getTrackers() {
        this.$api
          .authorize(this.$firebase.getToken())
          .get("/trackers/ads/")
          .then((data) => {
            this.trackList = data;
          });
      }
    }
  };
</script>

<style scoped lang="scss">
  .track-param {
    &:hover {
      background: #dadada;
    }
  }

  .track-param-title {
    display: block;
    min-width: 120px;
  }
</style>
