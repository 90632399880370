import axios from "axios";
import Vue from "vue";

function createApiClient(allowSimultaneousRequests = false) {
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE
  });

  const store = Vue.observable({
    loading: 0,
    error: null,
    meta: null
  });
  Object.defineProperty(api, "loading", {
    get: function () {
      return store.loading > 0;
    }
  });
  Object.defineProperty(api, "error", {
    get: function () {
      return store.error;
    }
  });
  Object.defineProperty(api, "meta", {
    get: function () {
      return store.meta;
    }
  });

  let cancelToken = null;
  api.cancel = function () {
    if (cancelToken) {
      cancelToken.cancel("USER_CANCEL");
    }
  };

  let authToken = null;
  api.authorize = function (token) {
    authToken = token;
    return api;
  };

  api.interceptors.request.use(async (config) => {
    if (!allowSimultaneousRequests && store.loading > 0) {
      cancelToken.cancel("NEW_REQUEST_COMING");
    }
    store.loading += 1;
    store.error = null;
    store.meta = null;
    cancelToken = axios.CancelToken.source();
    config.cancelToken = cancelToken.token;
    if (authToken) {
      let realToken =
        typeof authToken === "function" ? await authToken() : await authToken;
      if (realToken) {
        config.headers.Authorization = `Bearer ${realToken}`;
      }
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      store.loading -= 1;
      if (response.data && response.data.error) {
        store.error = response.data.error;
        return Promise.reject(store.error);
      }
      store.meta = {};
      Object.entries(response.data).forEach(([field, value]) => {
        if (field === "result") {
          return;
        }
        store.meta[field] = value;
      });
      return Promise.resolve(response.data.result);
    },
    (error) => {
      store.loading -= 1;
      if (error.message === "NEW_REQUEST_COMING") {
        return Promise.resolve();
      } else if (!error.response) {
        store.error = {
          code: "NETWORK_ERROR",
          message: "Network problem. Check your internet connection"
        };
      } else if (error.response.data && error.response.data.error) {
        store.error = error.response.data.error;
      } else {
        store.error = {
          code: error.response.status,
          message: error.message
        };
      }
      return Promise.reject(error);
    }
  );
  return api;
}

Object.defineProperty(Vue.prototype, "$api", {
  get: function () {
    if (!this._apiClient) {
      this._apiClient = createApiClient();
    }
    return this._apiClient;
  }
});

export { createApiClient };
