import LoginPage from "./LoginPage";
import MainPage from "./MainPage";
import GamesPage from "./GamesPage";
import OptionsPage from "./OptionsPage";
import TrackersPage from "./TrackersPage";
import UsersPage from "./UsersPage";
import CommentsPage from "./CommentsPage";
import TagsPage from "./TagsPage";

export default [
  {
    path: "/",
    component: MainPage,
    name: MainPage.name
  },
  {
    path: "/games",
    component: GamesPage,
    name: GamesPage.name
  },
  {
    path: "/tags",
    component: TagsPage,
    name: TagsPage.name
  },
  {
    path: "/options",
    component: OptionsPage,
    name: OptionsPage.name
  },
  {
    path: "/trackers",
    component: TrackersPage,
    name: TrackersPage.name
  },
  {
    path: "/users",
    component: UsersPage,
    name: UsersPage.name
  },
  {
    path: "/comments",
    component: CommentsPage,
    name: CommentsPage.name
  },
  {
    path: "/login",
    component: LoginPage,
    name: LoginPage.name
  }
];
