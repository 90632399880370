export default function getGameStatusColorClass(val) {
  if (!val) return "";
  return val === "ENABLED"
    ? "text-success"
    : val === "PAUSED"
    ? "text-warning"
    : val === "LICENSED"
    ? "text-info"
    : val === "DISABLED"
    ? "text-secondary"
    : "text-danger";
}