import Vue from "vue";
import { createApiClient } from "../plugins/api";

const store = Vue.observable({
  tags: null
});

let loader = null;

Vue.prototype.$store = Vue.prototype.$store || {};
Object.defineProperty(Vue.prototype.$store, "tags", {
  get: function () {
    if (store.tags === null) {
      store.tags = [];
      loadTags();
    }
    return store.tags;
  }
});

export default function loadTags() {
  if(loader === null) {
    loader = createApiClient()
      .get("/tags/", { params: { limit: 0 } })
      .then((data) => {
        store.tags = data;
      });
  }
  return loader;
}
