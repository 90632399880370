export default function timeFormatted(date, option) {
  const formatOption = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }
  return new Date(date * 1000).toLocaleString(
    "en-US",
    option ? {...formatOption, ...option} : formatOption
  )
}

