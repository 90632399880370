import Vue from "vue";
import App from "./App.vue";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "./plugins/fragment";
import "./plugins/api";
import "./plugins/firebase";

import "./helpers/helpers";

import "./stores/tags";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/styles/custom_elements.scss";

import router from "./plugins/router";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
