<template lang="pug">
  b-overlay.w-100(
    :show="isLoading"
    spinner-variant="success"
  )
    .d-flex.justify-content-center.py-5(v-if="pagination.offset + pagination.limit < pagination.total")
        b-button.w-25(variant="outline-success" @click="clickToShow") {{ buttonText }}
</template>

<script>
  export default {
    name: "ShowMoreButton",
    model: {
      prop: "pagination",
      event: "changePagination"
    },
    props: {
      buttonText: {
        type: String,
        default: "Show more"
      },
      isLoading: [Number, Boolean],
      pagination: Object
    },
    methods: {
      clickToShow() {
        const { offset, limit } = this.pagination;
        this.pagination.offset = offset + limit;
        this.$emit("changePagination", this.pagination);
      }
    }
  };
</script>
