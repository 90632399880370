<template lang="pug">
  PageStructure
    b-overlay(
      :show="$api.loading"
      spinner-variant="success"
    )
      GamesFilter(
        @change="changeFilter"
        @search="searchInList"
      )
      GameListAdditionalOptions(
        :games="games"
        :isShowedGif="withGif"
        @showGif="showGif"
        @add="addNewGame"
      )
      b-table.game-list(
        striped
        hover
        small
        sort-icon-left
        sticky-header="calc(100vh - 240px)"
        label-sort-clear=""
        label-sort-asc=""
        label-sort-desc=""
        tbody-tr-class="align-middle"
        sortBy="id"
        :sortDesc="true"
        :fields="gameTableFields"
        :items="games"
      )
        template(#cell(#)="game")
          | {{ game.index + 1 }}
        template(#cell(preview)="game")
          a(:href="getGameLink(game.item.slug, game.item.id)" target="_blank")
            img(:src="getGameImgSrc(game.item.id)" height="30")
        template(#cell(sdk_version)="game")
          b.text-danger(v-if="game.value === 'NO SDK'") {{ game.value }}
          b(v-else) {{ game.value }}
        template(#cell(platforms)="game")
          b {{ checkPlatforms(game.value) }}
        template(#cell(gif)="game")
          b(v-if="!withGif") N/A
          b.text-danger.text-nowrap(v-else-if="!checkGifImage(game.item.thumbs)") NONE
          b.text-success(v-else) EXIST
        template(#cell(status)="game")
          b(
            :class="$helpers.getGameStatusColorClass(game.value)"
          ) {{ game.value }}
        template(#cell(edit)="game")
          b-button(
            variant="outline-primary"
            size="sm"
            @click="selectGame(game.item)"
          )
            b-icon(icon="gear-fill")
    b-sidebar(
      v-model="isOpenedGameDetail"
      width="40vw"
      right
      shadow
      no-header
      no-close-on-backdrop
      backdrop-variant="secondary"
      backdrop
      @hidden="closeGameDetail"
    )
      GameDetail(
        v-if="selectedGame"
        :selectedGame="selectedGame"
        @saved="saveGame"
        @closed="closeGameDetail"
        @delete="deleteGame"
      )
</template>

<script>
  import PageStructure from "../components/PageStructure";
  import GamesFilter from "../components/GamesFilter";
  import GameDetail from "../components/GameDetail";
  import GameListAdditionalOptions from "../components/GameListAdditionalOptions";

  export default {
    name: "GamesPage",
    components: { PageStructure, GamesFilter, GameDetail, GameListAdditionalOptions },
    data() {
      return {
        games: [],
        gamesOriginalList: [],
        gameTableFields: [
          "#",
          {
            key: "id",
            sortable: true
          },
          {
            key: "title",
            sortable: true
          },
          {
            key: "preview",
            sortable: false
          },
          {
            key: "gif",
            label: "GIF",
            sortable: false
          },
          {
            key: "sdk_version",
            label: "SDK",
            sortable: true
          },
          {
            key: "platforms",
            label: "platforms",
            sortable: true,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            key: "internals.cr_1",
            label: "cr_1",
            sortable: true,
            tdClass: "border-start text-center",
            thClass: "text-center"
          },
          {
            key: "internals.cr_180",
            label: "cr_180",
            sortable: true,
            tdClass: "text-center",
            thClass: "text-center"
          },
          {
            key: "internals.cr_180_views",
            label: "cr_180_views",
            sortable: true,
            tdClass: "border-end text-center",
            thClass: "text-center"
          },
          {
            key: "internals.exit_1",
            label: "exit_1",
            sortable: true,
            tdClass: "border-start text-center",
            thClass: "text-center"
          },
          {
            key: "internals.exit_90",
            label: "exit_90",
            sortable: true,
            tdClass: "border-end text-center",
            thClass: "text-center"
          },
          {
            key: "internals.organic_plays",
            label: "organic_plays",
            sortable: true,
            tdClass: "border-start text-center",
            thClass: "text-center"
          },
          {
            key: "internals.organic_views",
            label: "organic_views",
            sortable: true,
            tdClass: "border-end text-center",
            thClass: "text-center"
          },
          {
            key: "internals.pg30_1",
            label: "pg30_1",
            sortable: true,
            tdClass: "border-start text-center",
            thClass: "text-center"
          },
          {
            key: "internals.pg30_90",
            label: "pg30_90",
            sortable: true,
            tdClass: "border-end text-center",
            thClass: "text-center"
          },
          {
            key: "internals.pg_1",
            label: "pg_1",
            sortable: true,
            tdClass: "border-start text-center",
            thClass: "text-center"
          },
          {
            key: "internals.pg_90",
            label: "pg_90",
            sortable: true,
            tdClass: "border-end text-center",
            thClass: "text-center"
          },
          {
            key: "status",
            sortable: true,
            tdClass: "text-center",
            thClass: "text-center"
          },
          "edit"
        ],
        isOpenedGameDetail: false,
        selectedGame: null,
        filter: null,
        search: null,
        withGif: false
      };
    },
    mounted() {
      this.getGames();
    },
    methods: {
      getGames() {
        this.$api
          .authorize(this.$firebase.getToken())
          .get("/games/", {
            params: {
              limit: 0,
              status: "ALL",
              with_tags: true,
              with_internals: true,
              with_platforms: true,
              with_thumbs: this.withGif,
              ...this.filter
            }
          })
          .then((data) => {
            this.games = data;
            this.gamesOriginalList = data;
          });
      },
      changeFilter(filter) {
        if (filter === null) {
          this.withGif = false;
        }
        this.filter = filter;
        this.getGames();
      },
      selectGame(game) {
        this.selectedGame = game;
        this.isOpenedGameDetail = true;
      },
      closeGameDetail() {
        this.selectedGame = null;
        this.isOpenedGameDetail = false;
      },
      saveGame(data) {
        this.selectedGame = {
          ...this.selectedGame,
          ...data
        };
        const gameIndex = this.games.findIndex(
          (g) => g.id === this.selectedGame.id
        );
        if (gameIndex === -1) {
          this.games.splice(0, 0, this.selectedGame);
          return;
        }
        this.games.splice(gameIndex, 1, this.selectedGame);
      },
      searchInList(searchRequest) {
        this.games = this.gamesOriginalList.filter((game) => {
          return (
            game["title"].toLowerCase().includes(searchRequest.toLowerCase()) ||
            game["id"].toString().includes(searchRequest)
          );
        });
      },
      addNewGame() {
        this.selectGame({ id: null });
      },
      deleteGame(id) {
        this.closeGameDetail();
        this.$api
          .authorize(this.$firebase.getToken())
          .delete(`/games/${id}/`)
          .then(() => {
            const gameIndex = this.games.findIndex((g) => g.id === id);
            this.games.splice(gameIndex, 1);
          });
      },
      checkGifImage(thumbs) {
        if (!thumbs) return false;
        const gifList = thumbs.filter((thmb) => thmb.path.includes("gif"));
        return gifList.length;
      },
      getGameImgSrc(id) {
        return `${
          process.env.VUE_APP_API_HOST
        }/data/${id}/thumbs/default.xs.jpg?v=${+new Date()}`;
      },
      getGameLink(slug, id) {
        return `${process.env.VUE_APP_API_MAIN_HOST}/${slug}-${id}/?scoresType=game`;
      },
      checkPlatforms(platforms) {
        return platforms.map((platform) => platform.substr(0, 1)).join("|");
      },
      showGif() {
        this.games = [];
        this.withGif = true;
        this.getGames();
      }
    }
  };
</script>

<style scoped lang="scss">
  .game-list {
    font-size: 0.8rem;
  }

  .game-gif-ind {
    height: 2.4rem;
  }
</style>
