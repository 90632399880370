<template lang="pug">
  .d-flex.flex-column.align-items-center.justify-content-center.flex-grow-1
    b-button(variant="outline-primary" @click="onLogin") Login with Google
    b-alert.mt-5(:show="!!lastLoggedInUser" variant="danger") User {{ lastLoggedInUser }} is not an administrator
</template>

<script>
  export default {
    name: "LoginPage",
    data() {
      return {
        lastLoggedInUser: null
      }
    },
    mounted() {
      const refreshed = [];
      this.$firebase.onUserChange((user) => {
        if (!user) {
          return;
        }
        if (refreshed.includes(user.id)) {
          return;
        }
        refreshed.push(user.id);
        this.$api
          .authorize(this.$firebase.getToken())
          .get("/users/me/")
          .then((user) => {
            if(user.status !== "ADMIN") {
              this.lastLoggedInUser = user.mail;
              this.$firebase.signOut();
              this.$firebase.refreshProfile();
              return;
            }
            this.$firebase.refreshProfile();
            this.$router.push({ name: this.$routerLinksPages.MainPage });
          });
      });
    },
    methods: {
      onLogin() {
        this.$firebase.signInWithProvider("google.com");
      }
    }
  };
</script>
