<template lang="pug">
  .d-block
    Header
    .d-block.p-3
      slot
</template>

<script>
  import Header from "./Header";

  export default {
    name: "PageStructure",
    components: { Header }
  };
</script>
