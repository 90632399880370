<template lang="pug">
  .d-block.pb-3.border-bottom
    .row
      .col-3
        b-form-input(
          v-model="searched"
          placeholder="Enter game title"
          type="text"
          @input="$emit('search', searched)"
        )
      .col-2
        b-form-select(
          v-model="filter.platforms"
          :options="platformList"
          @change="$emit('change', filter)"
        )
          template(#first)
            b-form-select-option(:value="null" disabled) Select platform
      .col-2
        b-form-select(
          v-model="filter.categories"
          :options="categoriesList"
          @change="$emit('change', filter)"
        )
          template(#first)
            b-form-select-option(:value="null" disabled) Select category
      .col-1.offset-4
        b-button.w-100(variant="danger" @click="resetFilter") Reset
</template>

<script>
  export default {
    name: "GamesFilter",
    data() {
      return {
        filter: {
          categories: null,
          platforms: null
        },
        searched: null,
        categoriesList: [
          "adventure",
          "arcade",
          "cars",
          "puzzle",
          "shooting",
          "skill",
          "sports"
        ],
        platformList: [
          { value: null, text: "ALL" },
          "MOBILE",
          "TABLET",
          "DESKTOP"
        ]
      };
    },
    methods: {
      resetFilter() {
        this.filter = {
          categories: null,
          platforms: null
        };
        this.searched = null;
        this.$emit("change", null);
      }
    }
  };
</script>
