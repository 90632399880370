<template lang="pug">
  PageStructure
</template>

<script>
  import PageStructure from "../components/PageStructure";

  export default {
    name: "MainPage",
    components: { PageStructure }
  };
</script>
