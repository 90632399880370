export default function isNotSupportedFullScreenApi() {
  return (
    // prettier-ignore
    window.navigator.userAgent.includes("iPad") ||
    window.navigator.userAgent.includes("Macintosh") &&
    window.navigator.userAgent.includes("Mobile") &&
    window.navigator.userAgent.includes("Version/12") ||
    window.navigator.userAgent.includes("Version/13")
  );
}
