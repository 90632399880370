<template lang="pug">
  .user-bar.d-flex.align-items-center.justify-content-between(v-if="$user")
    .d-flex.align-items-center.flex-grow-1.overflow-hidden
      b-avatar(:src="$user.logo")
      .user-name.px-2
        span.fs-6.fw-bold {{ $user.nickname }}
    b-button(v-b-modal.modal-sign-out variant="danger" size="sm")
      b-icon(icon="box-arrow-right")
    b-modal(
      id="modal-sign-out"
      hide-header
      ok-variant="outline-success"
      cancel-variant="outline-danger"
      ok-title="Sign out"
      @ok="signOutHandler"
    )
      p.fs-6.mb-2.text-center Are you sure you want to sign out?
</template>

<script>
  export default {
    name: "LoggedInUserInfo",
    methods: {
      signOutHandler() {
        this.$router.push({ name: this.$routerLinksPages.LoginPage });
        this.$firebase.signOut();
      }
    }
  };
</script>

<style scoped lang="scss">
  .user-bar {
    height: 40px;
    box-sizing: content-box;
  }

  .user-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
