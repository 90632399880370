<template lang="pug">
  PageStructure
    GameSelect(@selected="selectGame" @selectAll="getAllStrikedComments")
    .d-flex.border-bottom.py-3.mb-3(v-if="selectedGame")
      b-avatar(:src="`/data/${this.selectedGame.id}/thumbs/default.xs.jpg 320w`" size="6rem")
      .d-flex.align-items-center.ms-5.fs-5 {{ selectedGame.title }}
    b-tabs.mt-3(v-if="commentList.length" lazy)
      b-tab(title="Striked comments")
        b-overlay(
          :show="$api.loading"
          spinner-variant="success"
        )
          .d-block
            Comment(
              v-for="comment in reviewedList"
              :comment="comment"
              :key="comment.id"
              @lock="reviewComment(comment.id, comment.status === 'ENABLED')"
              @review="reviewComment(comment.id)"
              @remove="removeComment(comment.id)"
            )
      b-tab(title="All comments" :disabled="!selectedGame")
        b-overlay(
          :show="$api.loading"
          spinner-variant="success"
        )
          .d-block
            Comment(
              v-for="comment in comments"
              :comment="comment"
              :key="comment.id"
              @lock="reviewComment(comment.id, comment.status === 'ENABLED')"
              @review="reviewComment(comment.id)"
              @remove="removeComment(comment.id)"
            )
              Comment(
                v-if="comment.replies.length"
                v-for="reply in comment.replies"
                :comment="reply"
                :key="reply.id"
                @lock="reviewComment(reply.id, reply.status === 'ENABLED')"
                @review="reviewComment(reply.id)"
                @remove="removeComment(reply.id)"
              )
</template>

<script>
  import PageStructure from "../components/PageStructure";
  import GameSelect from "../components/GameSelect";
  import Comment from "../components/Comment";
  import ShowMoreButton from "../components/ShowMoreButton";

  export default {
    name: "CommentsPage",
    components: { PageStructure, GameSelect, Comment, ShowMoreButton },
    data() {
      return {
        selectedGame: null,
        commentList: []
      };
    },
    computed: {
      comments() {
        if (!this.commentList.length) return [];
        return this.filterParentComments(this.commentList);
      },
      reviewedList() {
        if (!this.commentList.length) return [];
        return this.commentList.filter((c) => !c.is_reviewed);
      }
    },
    methods: {
      selectGame(game) {
        this.commentList = [];
        this.selectedGame = game;
        this.getComments();
      },
      getAllStrikedComments() {
        this.commentList = [];
        this.selectedGame = null;
        this.getComments(true);
      },
      getComments(isNotReviewed) {
        const req = isNotReviewed
          ? {
              is_reviewed: 0
            }
          : {};

        this.$api
          .authorize(this.$firebase.getToken())
          .get("/comments/", {
            params: {
              game_id: this.selectedGame ? this.selectedGame.id : null,
              limit: 0,
              status: "ALL",
              with_users: true,
              ...req
            }
          })
          .then((data) => {
            this.commentList = data;
          });
      },
      filterParentComments(comments) {
        const parentComments = comments.filter(
          (comment) => comment.replied_to_id === null
        );
        return parentComments.map((comment) => {
          return {
            ...comment,
            replies: this.addRepliesToComment(comments, comment.id)
          };
        });
      },
      addRepliesToComment(comments, parentId) {
        return comments.filter((item) => item.replied_to_id === parentId);
      },
      removeComment(comment_id) {
        this.$api
          .authorize(this.$firebase.getToken())
          .delete(`/comments/${comment_id}/`, {
            comment_id
          })
          .then(() => {
            const removedItemIndex = this.commentList.findIndex(
              (comment) => comment.id === comment_id
            );
            this.commentList.splice(removedItemIndex, 1);
          });
      },
      reviewComment(comment_id, isLock) {
        const currentCommentStatus = isLock ? "PROHIBITED" : "ENABLED";
        this.$api
          .authorize(this.$firebase.getToken())
          .put(`/comments/${comment_id}/`, {
            comment_id,
            is_reviewed: 1,
            status: currentCommentStatus
          })
          .then(() => {
            const reviewedCommentIndex = this.commentList.findIndex(
              (c) => c.id === comment_id
            );
            const reviewedComment = {
              ...this.commentList[reviewedCommentIndex],
              is_reviewed: 1,
              status: currentCommentStatus
            };
            this.commentList.splice(reviewedCommentIndex, 1, reviewedComment);
          });
      }
    }
  };
</script>
