<template lang="pug">
  .header.d-flex.flex-grow-1
    TopMenu
</template>

<script>
  import TopMenu from "../components/TopMenu"

  export default {
    name: "Header",
    components: { TopMenu }
  };
</script>

<style scoped lang="scss">
  .header {
    box-sizing: content-box;
  }
</style>
