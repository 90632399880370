<template lang="pug">
  iframe.frame-sditor(
    :src="`https://cdn.game.fm/tiny.php?token=${this.$user.access_token}&p=${gameId}`"
  )
</template>

<script>
  export default {
    name: "GameContentEditor",
    props: {
      gameId: Number
    }
  };
</script>

<style scoped lang="scss">
  .frame-sditor {
    width: 100%;
    min-height: 50vh;
  }
</style>
